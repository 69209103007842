<template>
  <v-app>
    <div v-if="this.signedIn" class="viewWordsContent">

      <div id="nav">
        <router-link id="backToAdmin" to="/admin">&larr; back to Admin</router-link> |
        <router-link to="/nouns">Nouns</router-link> |
        <router-link to="/sentencesnouns">Sentences &mdash; Nouns</router-link> |
        <router-link to="/adjectives">Adjectives</router-link> |
        <router-link to="/sentencesadjectives">Sentences &mdash; Adjectives</router-link>
      </div>

      <h1>Add Nouns</h1>

      <v-card style="border-color: #fff;box-shadow: none; text-align:center">
        <v-row style="margin:0;padding:0">

          <v-col tile cols="1.5" v-for="i in docItems" :key="i">
            <v-chip label color="#8ecae6">{{i}}</v-chip>
          </v-col>

          <v-col cols="1.5">
            <v-chip label color="#8ecae6">Gen. + #</v-chip>
          </v-col>

          <v-col cols="1">
          </v-col>
        </v-row>
      </v-card>


        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row hide-details class="d-flex pa-0 ma-1">

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" ref="wordEnglishRef" v-model="wordEnglishField" class="ma-0 pa-0 text-english"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordNomField" class="ma-0 pa-0"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordGenField" class="ma-0 pa-0"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordAccField" class="ma-0 pa-0"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordDatField" class="ma-0 pa-0"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordInstField" class="ma-0 pa-0"></v-text-field>
          </v-col>

            <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
              <v-text-field hide-details tile outlined autocomplete="off" v-model="wordPrepField" class="ma-0 pa-0"></v-text-field>
          </v-col>



          <v-col tile cols="1.5" class="d-inline-flex pa-0 ma-1">

            <v-select
              :items="genders"
              label="Gen."
              dense hide-details outlined
              class="d-inline-flex pa-0 mr-1"
              height=56px
              v-model="wordGenderField"
              :class="getColourClass(wordGenderField)"
            ></v-select>

            <v-select
              :items="quantities"
              label="#"
              dense hide-details outlined
              class="d-inline-flex pa-0 ml-1"
              height=56px
              v-model="wordQuantityField"
              :class="getColourClass('quantity')"
            ></v-select>

          </v-col>


            <v-col cols="1" class="d-inline-flex pa-0 ma-1">
              <v-btn x-large elevation=0 color="green lighten-3" height=56px @click="addWord()">Add</v-btn>
            </v-col>
          </v-row>
        </v-card>










      <div style="text-align:center;margin:40px 0; padding:20px;border: 2px solid #343a40; border-radius:20px;">
        <h3>Highlight Legend</h3>
        <p style="margin:0;">How many sentences use this noun in this case?</p>
        <v-chip color="#000" label outlined class="ma-1">0 sentences</v-chip>
        <v-chip color="#d8f3dc" label class="ma-1">1 sentence</v-chip>
        <v-chip color="#b7e4c7" label class="ma-1">2 sentences</v-chip>
        <v-chip color="#95d5b2" label class="ma-1">3 sentences</v-chip>
        <v-chip color="#74c69d" label class="ma-1">4+ sentences</v-chip>

        <h3 style="margin-top:10px;">Database Stats</h3>
        <p style="margin:0;">There are <b>{{ (this.stats.numberOfMasculineNouns + this.stats.numberOfFeminineNouns + this.stats.numberOfNeuterNouns) }}</b> complete nouns (all fields filled in).
        Only 5 incomplete words will display at one time.</p>

        <h3 style="margin-top:10px;">More Info</h3>
        <p>If a delete button is disabled, it's because there is at least one sentence using the noun. Delete the noun from the sentences first.</p>
      </div>






      <div class="incompleteWords" v-if="IncompleteWords.length > 0">

      <h1>Incomplete Nouns</h1>


        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">

            <v-col tile cols="1.5" v-for="i in docItems" :key="i">
              <v-chip label color="#8ecae6">{{i}}</v-chip>
            </v-col>

            <v-col cols="1.5">
              <v-chip label color="#8ecae6">Gen. + #</v-chip>
            </v-col>

            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>

          </v-row>
        </v-card>


        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Incomplete in IncompleteWords" :key="Incomplete.id">
            <v-row hide-details class="d-flex pa-0 ma-1">

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.english" :disabled="inputsDisabled" :value="Incomplete.english" class="ma-0 pa-0 text-english"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.nom" :disabled="inputsDisabled" :value="Incomplete.nom" class="ma-0 pa-0" :class="getColourClass(Incomplete.nomSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.gen" :disabled="inputsDisabled" :value="Incomplete.gen" class="ma-0 pa-0" :class="getColourClass(Incomplete.genSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.acc" :disabled="inputsDisabled" :value="Incomplete.acc" class="ma-0 pa-0" :class="getColourClass(Incomplete.accSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.dat" :disabled="inputsDisabled" :value="Incomplete.dat" class="ma-0 pa-0" :class="getColourClass(Incomplete.datSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.inst" :disabled="inputsDisabled" :value="Incomplete.inst" class="ma-0 pa-0" :class="getColourClass(Incomplete.instSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Incomplete.prep" :disabled="inputsDisabled" :value="Incomplete.prep" class="ma-0 pa-0" :class="getColourClass(Incomplete.prepSentences.length)"></v-text-field>
            </v-col>



            <v-col tile cols="1.5" class="d-inline-flex pa-0 ma-1">


              <v-select
                :disabled="inputsDisabled"
                :items="genders"
                label="Gen."
                dense hide-details outlined
                class="d-inline-flex pa-0 mr-1"
                height=56px
                v-model="Incomplete.gender" :value="Incomplete.gender"
                :class="getColourClass(Incomplete.gender)"
              ></v-select>

              <v-select
                :disabled="inputsDisabled"
                :items="quantities"
                label="#"
                dense hide-details outlined
                class="d-inline-flex pa-0 ml-1"
                height=56px
                v-model="Incomplete.quantity" :value="Incomplete.quantity"
                :class="getColourClass('quantity')"
              ></v-select>

            </v-col>



              <v-col cols="1">
                <v-btn icon color="#e63946" :disabled="disableDeleteButton(Incomplete)" @click="deleteWord(Incomplete)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>

                <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(Incomplete)">
                  <v-icon>
                    mdi-check-circle
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>
        </transition-group>


</div>











<v-row class="mt-16">

  <v-col cols="4">
    <h1>Complete Nouns</h1>
  </v-col>

  <v-col cols="4">
  </v-col>


  <v-col cols="4">
    <v-text-field
        outlined
        placeholder="Search Complete Words"
        autocomplete="off"
        v-model="nounsSearchBar"
        @input="search()"
        :hint="searchedNouns.length + ' ' + this.searchedResultTag + ' found'"
      ></v-text-field>
  </v-col>

</v-row>


<!-- searched -->

<div class="incompleteWords" v-if="searchedNouns.length > 0">



        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">

            <v-col tile cols="1.5" v-for="i in docItems" :key="i">
              <v-chip label color="#8ecae6">{{i}}</v-chip>
            </v-col>

            <v-col cols="1.5">
              <v-chip label color="#8ecae6">Gen. + #</v-chip>
            </v-col>

            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>


          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="found in searchedNouns" :key="found.english">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.english" :disabled="inputsDisabled" :value="found.english" class="ma-0 pa-0 text-english"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.nom" :value="found.nom" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.nomSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.gen" :value="found.gen" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.genSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.acc" :value="found.acc" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.accSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.dat" :value="found.dat" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.datSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.inst" :value="found.inst" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.instSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="found.prep" :value="found.prep" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(found.prepSentences.length)"></v-text-field>
            </v-col>




            <v-col tile cols="1.5" class="d-inline-flex pa-0 ma-1">


              <v-select
                :disabled="inputsDisabled"
                :items="genders"
                label="Gen."
                dense hide-details outlined
                class="d-inline-flex pa-0 mr-1"
                height=56px
                v-model="found.gender" :value="found.gender"
                :class="getColourClass(found.gender)"
              ></v-select>

              <v-select
                :disabled="inputsDisabled"
                :items="quantities"
                label="#"
                dense hide-details outlined
                class="d-inline-flex pa-0 ml-1"
                height=56px
                v-model="found.quantity" :value="found.quantity"
                :class="getColourClass('quantity')"
              ></v-select>

            </v-col>



              <v-col cols="1">
                <v-btn icon color="#e63946" :disabled="disableDeleteButton(found)" @click="deleteWord(found)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>

                <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(found)">
                  <v-icon>
                    mdi-check-circle
                  </v-icon>
                </v-btn>
              </v-col>


            </v-row>
          </v-card>
        </transition-group>



      </div>



<!-- pagination -->
<div class="incompleteWords" v-if="Words.length > 0 && searchedNouns.length == 0">



        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">

            <v-col tile cols="1.5" v-for="i in docItems" :key="i">
              <v-chip label color="#8ecae6">{{i}}</v-chip>
            </v-col>

            <v-col cols="1.5">
              <v-chip label color="#8ecae6">Gen. + #</v-chip>
            </v-col>

            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>


          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Word in Words" :key="Word.english">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.english" :disabled="inputsDisabled" :value="Word.english" class="ma-0 pa-0 text-english"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.nom" :value="Word.nom" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.nomSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.gen" :value="Word.gen" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.genSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.acc" :value="Word.acc" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.accSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.dat" :value="Word.dat" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.datSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.inst" :value="Word.inst" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.instSentences.length)"></v-text-field>
            </v-col>

              <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                <v-text-field hide-details tile outlined autocomplete="off" v-model="Word.prep" :value="Word.prep" class="ma-0 pa-0" :disabled="inputsDisabled" :class="getColourClass(Word.prepSentences.length)"></v-text-field>
            </v-col>




            <v-col tile cols="1.5" class="d-inline-flex pa-0 ma-1">


              <v-select
                :disabled="inputsDisabled"
                :items="genders"
                label="Gen."
                dense hide-details outlined
                class="d-inline-flex pa-0 mr-1"
                height=56px
                v-model="Word.gender" :value="Word.gender"
                :class="getColourClass(Word.gender)"
              ></v-select>

              <v-select
                :disabled="inputsDisabled"
                :items="quantities"
                label="#"
                dense hide-details outlined
                class="d-inline-flex pa-0 ml-1"
                height=56px
                v-model="Word.quantity" :value="Word.quantity"
                :class="getColourClass('quantity')"
              ></v-select>

            </v-col>


              <v-col cols="1">
                <v-btn icon color="#e63946" :disabled="disableDeleteButton(Word)" @click="deleteWord(Word)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>

                <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(Word)">
                  <v-icon>
                    mdi-check-circle
                  </v-icon>
                </v-btn>
              </v-col>


            </v-row>
          </v-card>
        </transition-group>

        <template>
          <div class="text-center">
            <v-pagination
              color="#ffb703"
              v-model="page"
              :length="numberOfPages"
              @input="updatePagination()"
            ></v-pagination>
          </div>
        </template>


      </div>



    </div>
  </v-app>
</template>

<style media="screen">
  .v-application--wrap{
    min-height: 0;
  }
  h1{
    margin: 0 0 20px 0;
  }
  .viewWordsContent {
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 0;
    padding: 20px;
    text-align: left;
  }

  .v-text-field input {
    font-size: 1em;
    font-family: sans-serif;
  }

  .text-english {
    background-color: #ffb703;
    font-weight: bold;
  }

  .colour-white {
    background-color: #fff !important;
    padding: 0;
    margin: 0;
  }

  .colour-0 {
    background-color: #d8f3dc !important;
    padding: 0;
    margin: 0;
  }

  .colour-1 {
    background-color: #b7e4c7 !important;
    padding: 0;
    margin: 0;
  }

  .colour-2 {
    background-color: #95d5b2 !important;
    padding: 0;
    margin: 0;
  }

  .colour-3 {
    background-color: #74c69d !important;
    padding: 0;
    margin: 0;
  }

  .colour-masculine {
    background-color: #a2d2ff !important;
    padding: 0;
    margin: 0;
  }

  .colour-feminine {
    background-color: #ffafcc !important;
    padding: 0;
    margin: 0;
  }

  .colour-neuter {
    background-color: #b5e48c !important;
    padding: 0;
    margin: 0;
  }

  .colour-quantity {
    background-color: #6c757d !important;
    /* color: #000; */
    padding: 0;
    margin: 0;
  }

  .text-blue input {
    background-color: #blue !important;
  }

  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #fff !important;
    font-weight: bold;
    /* max-width: 20px; */
    /* width: 20px; */
  }


</style>











<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

export default {
  title: "Nouns",
  data() {
    return {
      signedIn: false,
      page: 1,
      pageLimit: 10,
      numberOfPages: 1,
      Words: [],
      IncompleteWords: [],
      WordsTotal: [],
      stats: [],

      searchedNouns: [],
      searchedResultTag: "results",

      nounsSearchBar: "",

      newItem: "",
      docItems: ['english', 'nom', 'gen', 'acc', 'dat', 'inst', 'prep'],
      genders: ['m', 'f', 'n'],
      quantities: ['s', 'p'],
      wordEnglishField: "",
      wordCategoryField: "",
      wordNomField: "",
      wordGenField: "",
      wordAccField: "",
      wordDatField: "",
      wordInstField: "",
      wordPrepField: "",
      wordGenderField: "",
      wordQuantityField: "",
      inputsDisabled: false
    }
  },
  methods: {
    getColourClass(value) {
      var classes = ['colour-white', 'colour-0', 'colour-1', 'colour-2', 'colour-3'];

      if (value == 'm') {
        return 'colour-masculine';
      } else if (value == 'f') {
        return 'colour-feminine';
      } else if (value == 'n') {
        return 'colour-neuter';
      } else if (value == 'quantity') {
        return 'colour-quantity';
      }
      if (value > 4) {
        return 'colour-3';
      } else {
        return classes[value];
      }
    },
    disableDeleteButton(Word) {

      return ((Word.numberOfCasePossibilities > 0) || this.inputsDisabled) ? true : false
    },
    toggleAllInputs() {
      this.inputsDisabled = !this.inputsDisabled;

      return this.inputsDisabled;
    },


    async search() {
      console.log(this.nounsSearchBar);

      await db.collection("Nouns").where("incomplete", "==", false).where("english", "==", this.nounsSearchBar)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {

              var word = doc.data();
              word.id = doc.id

              console.log(word.nom);

              this.searchedNouns.push(word)
              console.log(word);
              console.log(this.searchedNouns);

            })
          }
        })

        console.log(this.searchedNouns);

        for (var i = this.searchedNouns.length - 1; i >= 0; i--) {
          if (this.searchedNouns[i].english != this.nounsSearchBar) {
              this.searchedNouns.splice(i, 1);
          }
        }



        console.log(this.searchedNouns);

        this.searchedResultTag = (this.searchedNouns.length == 1) ? "result" : "results"


    },



    async updatePagination() {

      this.Words = []

      // console.log(this.page);



      var totalLength = (this.WordsTotal.length % this.pageLimit == 1) ? this.WordsTotal.length + 1 : this.WordsTotal.length

      console.log(this.WordsTotal.length % this.pageLimit);




      if ((this.WordsTotal.length % this.pageLimit != 0) && this.page == this.numberOfPages) {

        var remainder = this.WordsTotal.length % this.pageLimit
        this.Words = this.WordsTotal.slice(Math.max(this.WordsTotal.length - remainder, 0))


        console.log("LAST: " + this.Words);

      } else if ((this.pageLimit * this.page) <= totalLength) {

        console.log("HELLO!!!!!!!");

                  if (this.WordsTotal[(this.pageLimit * (this.page - 1))] == "NULL") {

                    var indextemp = (this.pageLimit * (this.page - 1))

                    for (var i = 0; i < this.pageLimit; i++) {
                      await db.collection("Nouns").where("incomplete", "==", false).orderBy("nounID").startAt(indextemp).limit(this.pageLimit)
                        .get()
                        .then((querySnapshot) => {
                          if (!querySnapshot.empty) {
                            querySnapshot.forEach((doc) => {

                              var word = doc.data();
                              word.id = doc.id
                              this.WordsTotal[indextemp] = word

                            })
                          }
                        })

                        indextemp++
                    }

                  }

                  var arrayComponent1 = (this.pageLimit * (this.page - 1))
                  var counter1 = 0;

                  while (counter1 < this.pageLimit) {
                    if (this.WordsTotal[arrayComponent1 + counter1] == null) {
                      break;
                    } else {
                      this.Words.push(this.WordsTotal[arrayComponent1 + counter1])
                      counter1++
                    }
                  }

      } else {

        console.log("WordsTotal length:  " + this.WordsTotal.length);
        console.log((this.page - 1 * this.pageLimit));

        while (this.WordsTotal.length < ((this.page - 1) * this.pageLimit)) {
          this.WordsTotal.push("NULL")
        }

        console.log(this.WordsTotal);

        var temp = ((this.page - 1) * this.pageLimit)

        await db.collection("Nouns").where("incomplete", "==", false).orderBy("nounID").startAt(temp).limit(this.pageLimit)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {

                var word = doc.data();

                word.id = doc.id
                this.WordsTotal.push(word)

              })
            }
          })



          var arrayComponent = ((this.page - 1) * this.pageLimit)
          var counter = 0;

          while (counter < this.pageLimit) {
            if (this.WordsTotal[arrayComponent + counter] == null) {
              break;
            }
            this.Words.push(this.WordsTotal[arrayComponent + counter])
            counter++
          }

      }


      console.log("Words length:  " + this.Words.length);
      console.log(this.Words);
      console.log("---");
      console.log("Words Total length:  " + this.WordsTotal.length);
      console.log(this.WordsTotal);


    },


    async addWord(){

      this.toggleAllInputs();

      // ADD SOME SORT OF CHECK TO ENSURE NO DUPLICATES

      var numberOfNouns = 0;

      var doc = await db.collection('Statistics').doc('STATISTICS').get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        var stats = doc.data();
        numberOfNouns = stats.numberOfNouns;
        console.log("number of words: " + numberOfNouns);
      }

      var numberOfGenderedNouns = [stats.numberOfMasculineNouns, stats.numberOfFeminineNouns, stats.numberOfNeuterNouns]

      var genderID;
      if (this.wordGenderField == 'm') {
        genderID = stats.numberOfMasculineNouns;
        numberOfGenderedNouns[0]++
      } else if (this.wordGenderField == 'f') {
        genderID = stats.numberOfFeminineNouns;
        numberOfGenderedNouns[1]++
      } else if (this.wordGenderField == 'n') {
        genderID = stats.numberOfNeuterNouns;
        numberOfGenderedNouns[2]++
      }


      if (this.wordEnglishField != ""
        && this.wordNomField != "" && this.wordGenField != "" && this.wordAccField != ""
        && this.wordDatField != "" && this.wordInstField != "" && this.wordPrepField != ""
        && this.wordGenderField != "" && this.wordQuantityField != "") {

        var nounID = numberOfGenderedNouns[0] + numberOfGenderedNouns[1] + numberOfGenderedNouns[2]

        await db.collection("Nouns").add({
          english: this.wordEnglishField,
          nom: this.wordNomField,
          gen: this.wordGenField,
          acc: this.wordAccField,
          dat: this.wordDatField,
          inst: this.wordInstField,
          prep: this.wordPrepField,
          gender: this.wordGenderField,
          quantity: this.wordQuantityField,
          "nomSentences": [],
          "genSentences": [],
          "accSentences": [],
          "datSentences": [],
          "instSentences": [],
          "prepSentences": [],
          incomplete: false,
          id: numberOfNouns,
          casePossibilities: [],
          numberOfCasePossibilities: 0,
          genderID: genderID,
          nounID: nounID
        });

        numberOfNouns++;

        await db.collection('Statistics').doc('STATISTICS').update({
          numberOfNouns: numberOfNouns,
          numberOfMasculineNouns: numberOfGenderedNouns[0],
          numberOfFeminineNouns: numberOfGenderedNouns[1],
          numberOfNeuterNouns: numberOfGenderedNouns[2]
        });


        this.wordEnglishField = "";
        this.wordNomField = "";
        this.wordGenField = "";
        this.wordAccField = "";
        this.wordDatField = "";
        this.wordInstField = "";
        this.wordPrepField = "";
        this.wordGenderField = "";
        this.wordQuantityField = "";
        this.$refs.wordEnglishRef.focus();



      } else {
        alert("complete all fields")
      }

      this.toggleAllInputs();

    },




    async updateWordOld(ref) {

      this.toggleAllInputs();

      console.log("originally incomplete? " + ref.incomplete);

      var incomplete = true
      if ((ref.english != null && ref.english != '') &&
          (ref.nom != null && ref.nom != '') &&
          (ref.gen != null && ref.gen != '') &&
          (ref.acc != null && ref.acc != '') &&
          (ref.dat != null && ref.dat != '') &&
          (ref.inst != null && ref.inst != '') &&
          (ref.prep != null && ref.prep != '') &&
          (ref.gender != null && ref.gender != '') &&
          (ref.quantity != null && ref.quantity != '')) {
        incomplete = false;
      }
      console.log("incomplete? " + incomplete);

      if (!incomplete) {
        //+------------------
        //| Get stats
        //+------------------
        var stats = await db.collection('Statistics').doc('STATISTICS').get();
        stats = stats.data();
        var numberOfMasculineNouns = stats.numberOfMasculineNouns;
        var numberOfFeminineNouns = stats.numberOfFeminineNouns;
        var numberOfNeuterNouns = stats.numberOfNeuterNouns;
        var task2countsMasc = stats.task2countsMasc;
        var task2countsFem = stats.task2countsFem;
        var task2countsNeut = stats.task2countsNeut;
        var numberOfGenderedNouns = [numberOfMasculineNouns, numberOfFeminineNouns, numberOfNeuterNouns]
        var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
        console.log(stats);

        console.log("~~~~~~~~~~~");

        var fetchedWord = await db.collection('Nouns').doc(ref.id).get();
        fetchedWord = fetchedWord.data();



        var genderID;
        if (ref.gender == 'm') {
          genderID = numberOfMasculineNouns;
          numberOfGenderedNouns[0]++
        } else if (ref.gender == 'f') {
          genderID = numberOfFeminineNouns;
          numberOfGenderedNouns[1]++
        } else if (ref.gender == 'n') {
          genderID = numberOfNeuterNouns;
          numberOfGenderedNouns[2]++
        }




        if ((ref.incomplete == true && incomplete == false) || (ref.gender != fetchedWord.gender)) {
          //add genderID
          console.log("Previously incomplete, adding genderID");
          await db.collection("Nouns").doc(ref.id).update({
            incomplete: false,
            english: ref.english,
            nom: ref.nom,
            gen: ref.gen,
            acc: ref.acc,
            dat: ref.dat,
            inst: ref.inst,
            prep: ref.prep,
            gender: ref.gender,
            quantity: ref.quantity,
            genderID: genderID
          });
        } else {
          await db.collection("Nouns").doc(ref.id).update({
            incomplete: false,
            english: ref.english,
            nom: ref.nom,
            gen: ref.gen,
            acc: ref.acc,
            dat: ref.dat,
            inst: ref.inst,
            prep: ref.prep,
            gender: ref.gender,
            quantity: ref.quantity
          });
        }

        if (ref.gender != fetchedWord.gender && fetchedWord.incomplete != true) {

          console.log("Gender is changed");

          var genders = ['m', 'f', 'n']

          var lastWordToChange, lastWordToChangeID;
          console.log("FIND: genderID: " + numberOfGenderedNouns[genders.indexOf(fetchedWord.gender)]);
          console.log("      gender:   " + fetchedWord.gender);
          await db.collection("Nouns").where("genderID", "==", (numberOfGenderedNouns[genders.indexOf(fetchedWord.gender)] - 1)).where("gender", "in", [fetchedWord.gender]).limit(1)
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  console.log("Found the last word");
                  lastWordToChange = doc.data();
                  lastWordToChangeID = doc.id;
                })
              }
            })

            console.log("Got last word to move");

            console.log(lastWordToChangeID);

          await db.collection("Nouns").doc(lastWordToChangeID).update({
            genderID: fetchedWord.genderID
          });

          console.log("updated that word");

          numberOfGenderedNouns[genders.indexOf(lastWordToChange.gender)]--
        }



        //If previous gender has changed -> update genderID to latest of the new gender
        //Set last genderID (highest) of old gender to the old genderID of the current word.

        //need to fetch the word to compare with 'ref'




        var task2CaseCollectionNames = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
        var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']

        var task2GenderCollection = (ref.gender) == 'm' ? 'MascTask2' : 'FemTask2';
        if (ref.gender == 'n') {
          task2GenderCollection = 'NeutTask2';
        }
        // arrayLevel --> task2Counts = ['m', 'f', 'n']
        var arrayLevel = (ref.gender) == 'm' ? 0 : 1;
        if (ref.gender == 'n') {
          arrayLevel = 2;
        }

        // numberOfGenderedWords[arrayLevel]++

        if ((ref.incomplete == true && incomplete == false) && ref.numberOfCasePossibilities >= 3) {
          console.log("NEED TO ADD TO TASK 2");

          for (var j = 0; j < caseAbbreviations.length; j++) {
            if (ref.casePossibilities.includes(caseAbbreviations[j])) {

              await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[j]).doc(ref.id).set({
                "countID" : task2Counts[arrayLevel][j],
                "english" : ref.english
              })
              task2Counts[arrayLevel][j]++

            }
          }

        } //end if to add task2

        //+------------------
        //| Update stats
        //+------------------
        await db.collection('Statistics').doc('STATISTICS').update({
          numberOfMasculineNouns: numberOfGenderedNouns[0],
          numberOfFeminineNouns: numberOfGenderedNouns[1],
          numberOfNeuterNouns: numberOfGenderedNouns[2],
          task2countsMasc: task2Counts[0],
          task2countsFem: task2Counts[1],
          task2countsNeut: task2Counts[2]
        })

      } else {
        alert("Did not update: need to complete all fields")
        console.log("Did not update: need to complete all fields");
      }

      this.toggleAllInputs();

    },


    async updateWord(ref){

      console.log(ref);

      this.toggleAllInputs();

      if ((ref.english != null && ref.english != '') &&
          (ref.nom != null && ref.nom != '') &&
          (ref.gen != null && ref.gen != '') &&
          (ref.acc != null && ref.acc != '') &&
          (ref.dat != null && ref.dat != '') &&
          (ref.inst != null && ref.inst != '') &&
          (ref.prep != null && ref.prep != '') &&
          (ref.gender != null && ref.gender != '') &&
          (ref.quantity != null && ref.quantity != '')) {

        var stats = await db.collection('Statistics').doc('STATISTICS').get();
        stats = stats.data();
        var numberOfMasculineNouns = stats.numberOfMasculineNouns;
        var numberOfFeminineNouns = stats.numberOfFeminineNouns;
        var numberOfNeuterNouns = stats.numberOfNeuterNouns;
        var numberOfNouns = stats.numberOfNouns;
        var task2countsMasc = stats.task2countsMasc;
        var task2countsFem = stats.task2countsFem;
        var task2countsNeut = stats.task2countsNeut;
        var numberOfGenderedNouns = [numberOfMasculineNouns, numberOfFeminineNouns, numberOfNeuterNouns]
        var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
        console.log(stats);

        console.log("~~~~~~~~~~~");

        var fetchedWord = await db.collection('Nouns').doc(ref.id).get();
        fetchedWord = fetchedWord.data();

        console.log(fetchedWord);

        if (fetchedWord.incomplete) {

          var genderID;
          if (ref.gender == 'm') {
            genderID = numberOfMasculineNouns;
            numberOfGenderedNouns[0]++
          } else if (ref.gender == 'f') {
            genderID = numberOfFeminineNouns;
            numberOfGenderedNouns[1]++
          } else if (ref.gender == 'n') {
            genderID = numberOfNeuterNouns;
            numberOfGenderedNouns[2]++
          }

          await db.collection("Nouns").doc(ref.id).update({
            incomplete: false,
            english: ref.english,
            nom: ref.nom,
            gen: ref.gen,
            acc: ref.acc,
            dat: ref.dat,
            inst: ref.inst,
            prep: ref.prep,
            gender: ref.gender,
            quantity: ref.quantity,
            genderID: genderID,
            nounID: numberOfNouns
          });

          numberOfNouns++

        } else if (fetchedWord.gender != ref.gender) {

          var tempOldNumber
          if (fetchedWord.gender == 'm') {
            numberOfGenderedNouns[0]--
            tempOldNumber = numberOfGenderedNouns[0]
          } else if (fetchedWord.gender == 'f') {
            numberOfGenderedNouns[1]--
            tempOldNumber = numberOfGenderedNouns[1]
          } else if (fetchedWord.gender == 'n') {
            numberOfGenderedNouns[2]--
            tempOldNumber = numberOfGenderedNouns[2]
          }

          var wordID
          await db.collection("Nouns").where("genderID", "==", tempOldNumber).where("gender", "in", [fetchedWord.gender])
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  console.log(wordID);
                  wordID = doc.id;
                  console.log(wordID);
                  })
                }
              })

          await db.collection('Nouns').doc(wordID).update({
            genderID: fetchedWord.genderID
          })

          // var genderID;
          if (ref.gender == 'm') {
            genderID = numberOfMasculineNouns;
            numberOfGenderedNouns[0]++
          } else if (ref.gender == 'f') {
            genderID = numberOfFeminineNouns;
            numberOfGenderedNouns[1]++
          } else if (ref.gender == 'n') {
            genderID = numberOfNeuterNouns;
            numberOfGenderedNouns[2]++
          }

          await db.collection("Nouns").doc(ref.id).update({
            incomplete: false,
            english: ref.english,
            nom: ref.nom,
            gen: ref.gen,
            acc: ref.acc,
            dat: ref.dat,
            inst: ref.inst,
            prep: ref.prep,
            gender: ref.gender,
            quantity: ref.quantity,
            genderID: genderID
          });

          //TASK 2 Stuff
          if (ref.numberOfCasePossibilities >= 3) {
            var task2CaseCollectionNames = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
            var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']
            //+------------------
            //+ remove from old gender Task 2
            //+------------------
            var task2GenderCollection = (fetchedWord.gender) == 'm' ? 'MascTask2' : 'FemTask2';
            if (fetchedWord.gender == 'n') {
              task2GenderCollection = 'NeutTask2';
            }
            // arrayLevel --> task2Counts = ['m', 'f', 'n']
            var arrayLevel = (fetchedWord.gender) == 'm' ? 0 : 1;
            if (fetchedWord.gender == 'n') {
              arrayLevel = 2;
            }

            for (var j = 0; j < caseAbbreviations.length; j++) {
              if (fetchedWord.casePossibilities.includes(caseAbbreviations[j])) {

                await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[j]).doc(ref.id).delete()
                task2Counts[arrayLevel][j]--

              }
            }



            //+------------------
            //+ add to new gender Task 2
            //+------------------

            task2GenderCollection = (ref.gender) == 'm' ? 'MascTask2' : 'FemTask2';
            if (ref.gender == 'n') {
              task2GenderCollection = 'NeutTask2';
            }
            // arrayLevel --> task2Counts = ['m', 'f', 'n']
            arrayLevel = (ref.gender) == 'm' ? 0 : 1;
            if (ref.gender == 'n') {
              arrayLevel = 2;
            }

            for (j = 0; j < caseAbbreviations.length; j++) {
              if (ref.casePossibilities.includes(caseAbbreviations[j])) {

                await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[j]).doc(ref.id).set({
                  "countID" : task2Counts[arrayLevel][j],
                  "english" : ref.english
                })
                task2Counts[arrayLevel][j]++

              }
            }
          }

        } else {

          await db.collection("Nouns").doc(ref.id).update({
            incomplete: false,
            english: ref.english,
            nom: ref.nom,
            gen: ref.gen,
            acc: ref.acc,
            dat: ref.dat,
            inst: ref.inst,
            prep: ref.prep,
            gender: ref.gender,
            quantity: ref.quantity
          });

        }

        //+------------------
        //| Update stats
        //+------------------
        await db.collection('Statistics').doc('STATISTICS').update({
          numberOfMasculineNouns: numberOfGenderedNouns[0],
          numberOfFeminineNouns: numberOfGenderedNouns[1],
          numberOfNeuterNouns: numberOfGenderedNouns[2],
          numberOfNouns: numberOfNouns,
          task2countsMasc: task2Counts[0],
          task2countsFem: task2Counts[1],
          task2countsNeut: task2Counts[2]
        })



      } else {
        alert("complete all fields")
      }




      this.toggleAllInputs();

    },



    async deleteWord(word) {

      this.toggleAllInputs();

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfNouns = stats.numberOfNouns;
      var numberOfMasculineNouns = stats.numberOfMasculineNouns;
      var numberOfFeminineNouns = stats.numberOfFeminineNouns;
      var numberOfNeuterNouns = stats.numberOfNeuterNouns;
      var numberOfGenderedNouns = [numberOfMasculineNouns, numberOfFeminineNouns, numberOfNeuterNouns]

      console.log("~~~~~~~~~~~");

      numberOfNouns--;
      if (!word.incomplete) {
        var arrayLevel = (word.gender) == 'm' ? 0 : 1;
        if (word.gender == 'n') {
          arrayLevel = 2;
        }
        // numberOfGenderedNouns[arrayLevel]--;

        //Here: ensure no gaps in incrementation of nounID, AND genderID

        //genderID
        var fetchedWord, fetchedWordID

        await db.collection("Nouns").where("gender", "==", word.gender).where("genderID", "==", numberOfGenderedNouns[arrayLevel])
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {

                fetchedWord = doc.data();
                console.log("fetchedWord: " + fetchedWord);
                fetchedWordID = doc.id;

              })
            }
          })

          await db.collection('Nouns').doc(fetchedWordID).update({
            genderID: word.genderID
          });



          // nounID
          var lastNounID = (numberOfGenderedNouns[0] + numberOfGenderedNouns[1] + numberOfGenderedNouns[2]) - 1

          await db.collection("Nouns").where("nounID", "==", lastNounID)
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {

                  fetchedWord = doc.data();
                  fetchedWordID = doc.id;

                })
              }
            })

            await db.collection('Nouns').doc(fetchedWordID).update({
              nounID: word.nounID
            });

            numberOfGenderedNouns[arrayLevel]--;




      }



      await db.collection("Nouns").doc(word.id).delete();




      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfMasculineNouns: numberOfGenderedNouns[0],
        numberOfFeminineNouns: numberOfGenderedNouns[1],
        numberOfNeuterNouns: numberOfGenderedNouns[2],
        numberOfNouns: numberOfNouns
      });

      this.toggleAllInputs();

    },

    async checkIfAdmin() {
      var user = await db.collection("Users").doc(this.uid).get();
      user = user.data();
      if (!user.admin) {
        this.$router.push({ name: 'Index'})
      }
      // return user.admin;
    }



  },
  firestore: {
    // Words: db.collection("Nouns").where("incomplete", "==", false),
    IncompleteWords: db.collection("Nouns").where("incomplete", "==", true).limit(5)
  },
  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.signedIn = true;
        this.username = user.displayName;

        this.checkIfAdmin(user.uid);


      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });







    this.stats = await db.collection("Statistics").doc("STATISTICS").get();
    this.stats = this.stats.data();

    this.numberOfPages = Math.ceil((this.stats.numberOfMasculineNouns + this.stats.numberOfFeminineNouns + this.stats.numberOfNeuterNouns) / this.pageLimit);


    await db.collection("Nouns").where("incomplete", "==", false).orderBy("nounID").startAt(0).limit(this.pageLimit)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {

            var word = doc.data();

            word.id = doc.id;

            this.Words.push(word)
            this.WordsTotal.push(word)
            // var wordID = doc.id;

            console.log(word);




          })
        }
      })





    console.log("~~~~");
    console.log("~~~~");
    console.log(this.Words);
    console.log("~~~~");
    console.log("~~~~");



  },
  mounted(){

  }
}
</script>










<!--  -->
